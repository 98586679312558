<template>
	<h1>Contact Arthur</h1>
	<h3>Contributions</h3>
	<p>
		Contibutions are currently closed. Sign up for the
		<router-link :to="{ name: 'Home' }">wait list</router-link> to be notified when we open.
	</p>
	<br />
	<h3>Removals</h3>
	<p>
		To have your work removed from Arthur, please
		<router-link :to="{ name: 'LegalRemoval' }">click here</router-link>.
	</p>
	<br />
	<h3>Image Rights</h3>
	<p>
		For permission to publish or use any artwork, please reach out to the artist or their estate. If this
		is not possible, try contacting
		<a target="_blank" href="https://arsny.com/">The Artist Rights Society</a>.
	</p>
	<p>
		Please note that many low-level use cases (eg. presentations, class materials, school assignments)
		fall under the DMCA's
		<a target="_blank" href="https://www.dmca.com/faq/Fair-Use">Fair Use Policy</a>.
	</p>
	<br />
	<h3>Everything Else</h3>
	<p>Find us at <a href="mailto:hello@arthur.io">hello@arthur.io</a></p>
</template>

<script>
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'GeneralContact',
	setup() {
		injectMetaData({
			title: 'Arthur.io • Contact',
		})
	},
}
</script>
